import React, { useEffect, useState } from 'react';
import { Box, Button, TextareaAutosize, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ExpandLess, Menu, MenuBook, QuestionAnswer } from '@material-ui/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Close, PlayCircle } from '@mui/icons-material';
import { PRIMARY } from '../../theme/utils/color';
import ReactPlayer from 'react-player';
import { useFormation } from '../../services/useFormation';
import { CommentaireComponent } from './components/CommentaireComponent';
import { AuthStorage } from '../../utils/AuthStorage';
import { useCommentaire } from '../../services/useCommentaire';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { fetchAllFormations } from '../../features/formations/useFormation';
import { createHttp } from '../../api/http';

const styles = {
	button: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
		cursor: 'pointer',
		alignContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(90, 90, 90, .3)',
		color: 'black',
		padding: '10px 0',
		'&:hover': {
			backgroundColor: PRIMARY.green,
			color: 'white',
			padding: '10px 0'
		}
	},
	selected: {
		backgroundColor: PRIMARY.green,
		color: 'white',
		padding: '10px 0'
	}
};

export const CoursePage = ({ customContent, setCustomContent }) => {
	const isLoading = useSelector((state) => state.loading.isLoading);

	const [showSidebar, setShowSidebar] = useState(true);
	const { courseName: courseId, lesson } = useParams(); // Récupère le paramètre de l'URL

	const navigate = useNavigate();
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [isLessonPage, setIsLessonPage] = useState(true);
	const [formation, setFormation] = useState();
	const [currentLesson, setCurrentLesson] = useState();
	const [allLessons, setAllLessons] = useState([]);
	const [commentaire, setCommentaire] = useState('');
	const [isFormationDone, setIsFormationDone] = useState(false);
	const dispatch = useDispatch();
	setTimeout(() => dispatch(setLoading(false)), 45000);
	const handleSendFeedback = async () => {
		const user = AuthStorage.isAuth()?.user;
		const comment = {
			text: commentaire,
			user_id: user.id,
			formation_id: formation.id
		};
		dispatch(setLoading(true));
		const response = await useCommentaire.postComment(comment);
		getOneFormation(formation.id);
		setCommentaire('');
		setTimeout(() => dispatch(setLoading(false)));
	};

	const getOneFormation = (formationId) => {
		const user = AuthStorage.isAuth()?.user;
		useFormation
			.getByIdFormation(formationId)
			.then((response) => {
				setFormation(response.data);
				let allLesson = [];
				const userTaking = response.data.users_taking.filter(
					(userTaking) => userTaking.user_id === user?.id
				);
				if (userTaking.length !== 0) setIsFormationDone(userTaking[0].isDone);
				response.data.chapters.forEach((chapter) => {
					allLesson.push(...chapter?.lessons);
				});
				setAllLessons(allLesson);
				const currentLesson = lesson == 0 ? 1 : lesson;
				setCurrentLesson(allLesson[currentLesson - 1]);
			})
			.catch((e) => {});
	};
	const sortChapters = (chapters) => chapters.sort((a, b) => a.order - b.order);

	const prevLesson = () => {
		dispatch(setLoading(true));
		const prevLesson = allLessons[allLessons.indexOf(currentLesson) - 1];
		setCurrentLesson(prevLesson);
		setTimeout(() => dispatch(setLoading(false)), 1000);
	};
	const nextLesson = async () => {
		dispatch(setLoading(true));
		const nextLesson = allLessons[allLessons.indexOf(currentLesson) + 1];
		const isDone = nextLesson == allLessons[allLessons.length - 1];

		useFormation.makeFormationDone(
			formation.uuid,
			allLessons.indexOf(nextLesson) + 1,
			isDone
		);
		setCurrentLesson(nextLesson);

		if (isDone) {
			const res = await createHttp().get('/dashboard/my-plateforme');
			dispatch(fetchAllFormations(res.data));
		}
		setTimeout(() => dispatch(setLoading(false)), 1000);
	};

	const removeSidebarContent = () => {
		setCustomContent(null);
	};

	const setCustomContentOfPage = () => {
		setCustomContent(
			<>
				{formation && (
					<Box
						sx={{
							height: 'auto',
							overflow: 'scroll !important',
							width: '100% !important'
						}}>
						<Box
							sx={{
								display: 'flex',
								padding: '5px',
								alignItems: 'center'
							}}>
							<IconButton
								sx={{ display: { lg: 'none' } }}
								onClick={() => removeSidebarContent()}>
								<Close />
							</IconButton>

							<IconButton
								onClick={() => {
									setCustomContent(null);
									navigate('/dashboard/courses');
								}}>
								<ExpandLess style={{ transform: 'rotate(-90deg)' }} />
							</IconButton>
							<Typography sx={{ fontSize: '16px' }}>
								{formation.title}
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'flex',
								// marginBottom: '20px',
								width: '100% !important'
							}}>
							<Box
								sx={styles.button}
								className={isLessonPage ? 'dashboard-sidebar-focused' : ''}
								onClick={() => setIsLessonPage(true)}>
								<MenuBook />
								<Typography sx={{ fontSize: '24px' }}>Leçons</Typography>
							</Box>
							<Box
								sx={styles.button}
								className={!isLessonPage ? 'dashboard-sidebar-focused' : ''}
								onClick={() => setIsLessonPage(false)}>
								<QuestionAnswer />
								<Typography sx={{ fontSize: '24px' }}>Q&R</Typography>
							</Box>
						</Box>
						{isLessonPage && (
							<Box sx={{ margin: '0 20px' }}>
								{sortChapters(formation.chapters).map((course, i) => {
									return (
										<div key={i}>
											<Typography
												sx={{
													fontSize: '18px',
													fontWeight: 'bold',
													marginBottom: '10px'
												}}>
												{course.order}. {course.title}
											</Typography>
											{sortChapters(course.lessons).map((lesson, i) => {
												return (
													<div
														onClick={() => {
															if (isFormationDone) setCurrentLesson(lesson);
														}}
														key={i}
														style={{ marginLeft: '10px', cursor: 'pointer' }}>
														<PlayCircle style={{ height: 16, width: 16 }} />
														{lesson.order}. {lesson.lesson_title}
													</div>
												);
											})}
										</div>
									);
								})}
								{(currentLesson === allLessons[allLessons.length - 1] ||
									isFormationDone) && (
									<Link to={`/dashboard/quizz/${formation.uuid}`}>
										<Button
											color={'success'}
											variant={'contained'}
											size='small'>
											<Typography sx={{ fontSize: '16px', padding: '10px' }}>
												Passer le quizz
											</Typography>
										</Button>
									</Link>
								)}
							</Box>
						)}
						{!isLessonPage && (
							<Box>
								<CommentaireComponent commentaires={formation.commentaires} />
							</Box>
						)}
					</Box>
				)}
			</>
		);
	};

	useEffect(() => {
		if (!formation) {
			dispatch(setLoading(true));
			getOneFormation(courseId);
			setTimeout(() => dispatch(setLoading(false)), 3000);
		}
		setIsInitialRender(false);
		setCustomContentOfPage();
	}, [
		isInitialRender,
		setCustomContent,
		navigate,
		isLessonPage,
		courseId,
		allLessons,
		currentLesson,
		setIsLessonPage,
		formation,
		lesson
	]);

	const lessonPage = Number.parseInt(allLessons.length);

	return (
		formation &&
		currentLesson && (
			<>
				<Box
					sx={{
						position: 'relative',
						marginLeft: '15em'
					}}>
					{currentLesson !== allLessons[0] && (
						<Button
							onClick={prevLesson}
							sx={{
								position: 'absolute',
								zIndex: 10000,
								left: '5em',
								top: '40%',
								transform: 'translate(-50%, -50%)'
							}}>
							<ExpandLess
								style={{
									transform: 'rotate(-90deg)',
									height: '45px',
									width: '45px',
									color: 'white'
								}}
							/>
						</Button>
					)}
					{currentLesson !== allLessons[allLessons.length - 1] && (
						<Button
							onClick={nextLesson}
							sx={{
								position: 'absolute',
								zIndex: 10000,
								top: '40%',
								right: '0',
								transform: 'translate(-50%, -50%)'
							}}>
							<ExpandLess
								style={{
									transform: 'rotate(90deg)',
									height: '45px',
									width: '45px',
									color: 'white'
								}}
							/>
						</Button>
					)}
					{!customContent && (
						<div onClick={setCustomContentOfPage}>
							<Menu />
						</div>
					)}
					{currentLesson && (
						<Box sx={{ padding: '3%' }}>
							<ReactPlayer
								url={currentLesson?.youtube_video}
								width='auto'
								height='600px'
								controls
							/>
							<Box
								sx={{
									padding: '15px'
								}}>
								<Typography
									sx={{
										fontSize: '18px',
										fontWeight: 'bold',
										marginBottom: '10px'
									}}>
									Titre de la leçon : {currentLesson?.lesson_title}
								</Typography>
							</Box>
							<Box
								sx={{
									padding: '15px'
								}}>
								<Typography
									sx={{
										fontSize: '18px',
										marginBottom: '10px'
									}}>
									Laisser une question (*)
								</Typography>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'start',
										gap: '20px'
									}}>
									<TextareaAutosize
										style={{ width: '50%', height: '75px' }}
										value={commentaire}
										onChange={(e) => setCommentaire(e.target.value)}
									/>
									<Button
										variant='contained'
										disabled={!commentaire}
										onClick={() => handleSendFeedback()}>
										Envoyer la question
									</Button>
								</div>
							</Box>
						</Box>
					)}
					{!currentLesson && <div>Loading</div>}
				</Box>
			</>
		)
	);
};
